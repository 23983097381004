<template>
  <div class="export">
    <v-menu
      rounded
      offset-y
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-bind="attrs"
          class="text-none"
          v-on="on"
        >
          Export
          <v-icon
            right
          >
            {{ icons.mdiFileExport }}
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          key="pdf"
          link
          @click="openExportDialog('pdf')"
        >
          <v-list-item-title>
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiFilePdf }}
            </v-icon>Pdf
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          key="excel"
          link
          @click="openExportDialog('excel')"
        >
          <v-list-item-title>
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiFileExcel }}
            </v-icon>Excel
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="showExportDialog"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>Select Columns to Export</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(header, index) in headers"
                :key="index"
                cols="12"
                sm="12"
                md="6"
              >
                <v-checkbox
                  v-model="selectedHeaders"
                  :label="header.text"
                  :value="header.value"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showExportDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="isExporting"
            @click="confirmExport"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import * as XLSX from 'xlsx'
import {
  mdiFilePdf, mdiFileExcel, mdiFileExport,
} from '@mdi/js'
import JsPDF from 'jspdf'

import AutoTable from 'jspdf-autotable'

export default {
  props: {
    headers: {
      type: Array,
      default: null,

    },
    dataEndpoint: {
      type: String,
      default: null,

    },
    dataPath: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,

    },
    subTitle: {
      type: String,
      default: null,

    },
    orientation: {
      type: String,
      default: 'portrait',
    },
  },
  data() {
    return {
      icons: {
        mdiFilePdf,
        mdiFileExcel,
        mdiFileExport,
      },
      data: [],
      showExportDialog: false,
      isExporting: false,
      selectedHeaders: [],
    }
  },
  methods: {
    openExportDialog(type) {
      this.exportType = type
      this.showExportDialog = true
      this.selectedHeaders = this.headers.map(header => header.value)
    },
    confirmExport() {
      if (this.selectedHeaders.length === 0) {
        this.$toast.error('Please select at least one column to export.')

        return
      }
      this.isExporting = true
      this.fetchApiData(this.exportType)
    },
    fetchApiData(exportType) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL
      const urlWithAll = new URL(this.dataEndpoint, baseUrl)
      urlWithAll.searchParams.set('perPage', 'all')
      axios
        .get(urlWithAll.toString())
        .then(response => {
          const dataPath = this.dataPath || ''
          const responseData = dataPath ? response.data[dataPath] : response.data

          // Convert object to array if necessary
          const formattedData = Array.isArray(responseData) ? responseData : Object.values(responseData)
          this.data = formattedData

          if (this.data.length === 0) {
            this.$toast.error('Cannot export from an empty table.')

            return
          }
          if (exportType === 'pdf') {
            this.generatePdf()
          } else {
            this.generateXls()
          }
          this.showExportDialog = false
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Something went wrong while exporting')
        }).finally(() => {
          this.isExporting = false
        })
    },
    generatePdf() {
      const doc = new JsPDF(this.orientation, 'pt')
      let startY = 80

      const title = this.toTitleCase(this.title)

      // Setting up the document titles
      doc.text(title, doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' })
      if (this.subTitle) {
        startY = 100
        doc.text(this.subTitle, doc.internal.pageSize.getWidth() / 2, 80, { align: 'center' })
      }

      // Define columns and format data
      const columns = this.defineColumns()
      const formattedData = this.formatData(columns)

      // AutoTable configuration
      AutoTable(doc, {
        startY,
        body: formattedData,
        columns: columns.map(col => ({ header: col.header, dataKey: col.dataKey })),
        didDrawPage: data => {
          // Page numbering and date handling
          let str = `Page ${doc.internal.getNumberOfPages()}`
          if (typeof doc.putTotalPages === 'function') {
            str = `${str} of {total_pages_count_string}`
          }
          doc.setFontSize(10)

          const { pageSize } = doc.internal
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)

          const currentDate = moment().format('DD/MM/YYYY hh:mm')
          const position = this.orientation === 'landscape' ? 800 : 550
          doc.text(currentDate, position, pageHeight - 10, { align: 'right' })
        },
      })

      // Total page number plugin
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages('{total_pages_count_string}')
      }

      // Save the PDF
      doc.save(`${this.title}.pdf`)
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    generateXls() {
      const columns = this.defineColumns()
      const formattedData = this.formatData(columns)

      // Create a worksheet with formatted data
      const workSheet = XLSX.utils.json_to_sheet(formattedData)

      // Add custom headers to the first row of the worksheet
      XLSX.utils.sheet_add_aoa(workSheet, [columns.map(col => col.header)], {
        origin: 'A1', // Place headers starting at the first cell
      })

      // Calculate the range for the worksheet
      const endColumnIndex = columns.length - 1
      const endRowIndex = formattedData.length // Number of data rows

      // Adjust the range of the worksheet
      workSheet['!ref'] = XLSX.utils.encode_range({
        s: { c: 0, r: 0 }, // Start at the first cell
        e: { c: endColumnIndex, r: endRowIndex }, // End at the last data cell
      })

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, workSheet, 'Data')

      // Write the workbook to a file
      XLSX.writeFile(wb, `${this.title}.xlsx`)
    },

    defineColumns() {
      return this.selectedHeaders.map(header => {
        const headerText = this.headers.find(h => h.value === header)?.text || header

        return { header: headerText, dataKey: header }
      })
    },

    // Utility function to format data
    formatData(columns) {
      return this.data.map(row => columns.reduce((obj, col) => {
        obj[col.dataKey] = this.getNestedValue(row, col.dataKey)

        return obj
      }, {}))
    },

    // Helper function to safely access nested properties and handle arrays
    getNestedValue(obj, path) {
      const value = path.split('.').reduce((value, key) => (value ? value[key] : undefined), obj)
      if (Array.isArray(value)) {
        return value.map(item => item.description || item.value).join(', ')
      }
      if (['created_at', 'due_date', 'payment_date', 'customer.customer_plan.effective_end_date', 'end_date'].includes(path)) {
        const filterName = this.title.toLowerCase().includes('invoice') ? 'formatDay' : 'formatDate'

        return this.$options.filters[filterName](value)
      }

      return value
    },

  },

}
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Add contact numbers for {{ user.name }}</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="saveContactNumbers"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <template
                  v-for="(phone, index) in form.phone_numbers"
                >
                  <v-col
                    :key="index"
                    cols="12"
                  >
                    <v-row>
                      <v-col cols="10">
                        <v-text-field
                          v-model="phone.phone_number"
                          :label="index === 0 ? 'Primary Phone Number' : 'Additional Phone Number'"
                          :placeholder="index === 0 ? 'Enter phone number' : 'Enter additional phone number'"
                          :rules="phoneRules"
                          :error="form.errors.has(`phone_numbers.${index}.phone_number`)"
                          :error-messages="form.errors.get(`phone_numbers.${index}.phone_number`)"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="2"
                        class="d-flex align-center"
                      >
                        <v-tooltip
                          v-if="index > 0"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="removePhoneNumber(index)"
                            >
                              <v-icon>{{ icons.mdiMinusCircle }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove additional contact</span>
                        </v-tooltip>
                        <v-tooltip
                          v-if="index === form.phone_numbers.length - 1"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="addPhoneNumber"
                            >
                              <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Add additional contact</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>

                <v-col
                  cols="12"
                  class="mt-0 pt-0"
                >
                  <v-checkbox
                    v-model="usePhoneNumberAsAccountNumber"
                    label="Use primary phone number as account number"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'
import { mapGetters } from 'vuex'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      phone_numbers: [{ phone_number: '' }],
      account_number: '',
      allow_null_internet_plan_id: true,
    }),
    usePhoneNumberAsAccountNumber: true,
    icons: {
      mdiPlusCircle,
      mdiMinusCircle,
    },
  }),
  computed: {
    ...mapGetters(['selectedStation']),

    // Computed property to get the first phone number
    firstPhoneNumber() {
      return this.form.phone_numbers.length > 0 ? this.form.phone_numbers[0].phone_number : ''
    },
  },
  watch: {
    user(value) {
      if (Object.keys(value).length > 0) {
        this.form.update(value)

        this.form.phone_numbers = value.phone_numbers?.length ? value.phone_numbers : [{ phone_number: '' }]
        this.form.account_number = this.user.customer.account_number
        this.form.internet_plan_id = this.user.customer.customer_plan ? this.user.customer.customer_plan.internet_plan_id : ''
        this.form.internet_connection_type = this.user.customer.internet_connection_type
        this.form.password = this.user.customer.router_auth_password
        this.form.station_id = this.user.customer.station_id
        this.form.billing_type = this.user.customer.billing_type
        let installationFee = 0
        if (this.user.customer.should_pay_installation_fee) {
          this.form.should_pay_installation_fee = this.user.customer.should_pay_installation_fee
          installationFee = this.user.customer.installation_fee
        }
        this.form.installation_fee = installationFee
        if (this.user.customer.ip_assignment) {
          this.form.mac_address = this.user.customer.mac_address
          this.form.network_address = this.user.customer.ip_assignment.ip_network.network_address
        }
      }
    },
    usePhoneNumberAsAccountNumber(value) {
      if (value && this.form.phone_numbers.length > 0) {
        this.form.account_number = this.firstPhoneNumber
      } else {
        this.form.account_number = this.user.customer.account_number
      }
    },
    firstPhoneNumber(newVal) {
      if (this.usePhoneNumberAsAccountNumber) {
        this.form.account_number = newVal
      }
    },
  },
  methods: {
    addPhoneNumber() {
      this.form.phone_numbers = [...this.form.phone_numbers, { phone_number: '' }]
    },
    removePhoneNumber(index) {
      if (this.form.phone_numbers.length > 1) {
        this.form.phone_numbers = this.form.phone_numbers.filter((_, i) => i !== index)
      } else {
        this.form.phone_numbers = [{ phone_number: '' }]
      }
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.reset()
      this.form.phone_numbers = [{ phone_number: '' }]
    },
    saveContactNumbers() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .put(`customers/${this.user.customer.id}`)
          .then(response => {
            this.$toast.success('Customer phone numbers updated successfully')
            this.$emit('close')
            this.$emit('customer-contact-number-added', response.data)
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>

<template>
  <v-autocomplete
    v-model="localSelectedTagName"
    :items="tags"
    :loading="isTagsLoading"
    :search-input.sync="tags_search"
    clearable
    hide-details
    cache-items
    hide-no-data
    outlined
    :label="label"
    placeholder="Search or add a new tag"
    :multiple="multiple"
    :rules="[v => !!v || 'Tag is required']"
    :error="formErrors.has('tag_id')"
    :error-messages="formErrors.get('tag_id')"
    allow-overflow
    @blur="onBlur"
  >
    <template v-slot:no-data>
      <v-list-item @click="selectCustomTag">
        <v-list-item-title>
          Add "{{ tags_search }}"
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-if="multiple"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item }}
      </v-chip>
      <span v-else>{{ data.item }}</span>
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    value: {
      type: [Array],
      required: true,
    },
    label: {
      type: String,
      default: 'Tags (optional)',
    },
    formErrors: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localSelectedTagName: this.value,
      tags: this.value,
      isTagsLoading: false,
      tags_search: null,
    }
  },
  watch: {
    tags_search(value) {
      this.getTags(value)
    },
    localSelectedTagName(newValue) {
      this.$emit('update:value', newValue) // Emit back the new value to parent
      this.$emit('change', newValue)
    },
    value(newValue) {
      this.localSelectedTagName = newValue // Keep the local value in sync when the parent updates it
      this.tags = newValue
    },
  },
  mounted() {
    if (this.value.length === 0) {
      this.getTags()
    }
  },
  methods: {
    getTags: _.debounce(function (value) {
      const searchValue = value || ''
      this.isTagsLoading = true
      axios
        .get(`tags/customer?search=${encodeURIComponent(searchValue)}&perPage=10`)
        .then(response => {
          const fetchedTags = response.data?.tags || []
          const tags = fetchedTags.map(tag => tag.name?.en || searchValue)

          if (tags.length === 0) {
            this.tags = [searchValue]
          } else {
            this.tags = tags
          }
        })
        .catch(error => {
          const errorMsg = error.response?.data?.message || 'Error fetching tags'
          this.$toast.error(errorMsg)
        })
        .finally(() => {
          this.isTagsLoading = false
        })
    }, 500),

    selectCustomTag() {
      const newTag = this.tags_search

      if (!this.tags.includes(newTag)) {
        this.tags = [...this.tags, newTag]
        this.localSelectedTagName = this.multiple
          ? [...this.localSelectedTagName, newTag]
          : newTag
      }
    },

    onBlur() {
      if (this.tags_search && !this.tags.includes(this.tags_search)) {
        this.selectCustomTag()
      }
    },

    remove(tag) {
      if (this.multiple) {
        this.localSelectedTagName = this.localSelectedTagName.filter(
          selected => selected !== tag,
        )
      } else {
        this.localSelectedTagName = '' // Clear when single mode
      }
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="addTags"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <customer-tag-search-autocomplete
                    v-model="form.tags"
                    class="mb-6"
                    label="Tags"
                    :form-errors="form.errors"
                    @change="onTagChange"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import CustomerTagSearchAutocomplete from '../partials/CustomerTagSearchAutocomplete.vue'

export default {
  components: {
    CustomerTagSearchAutocomplete,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    title: {
      type: String,
      default: 'Add Tags',
    },
    selectedCustomerIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    form: new Form({
      tags: [],
      customer_ids: [],
    }),
  }),
  watch: {
    selectedCustomerIds() {
      this.form.customer_ids = this.selectedCustomerIds
    },
  },
  methods: {
    // Close dialog and reset the form
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    onTagChange(tags) {
      this.form.tags = tags
    },
    addTags() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.form
        .post('customers/assign-tags')
        .then(this.handleSuccess)
        .catch(this.handleError)
    },

    // Success handler
    handleSuccess(response) {
      this.$emit('tags-added')
      this.closeDialog()
      this.$toast.success(response.data.message)
    },
    handleError(error) {
      // Log the error or display a message to the user
      console.error('Error assigning tags:', error)
      this.$toast.error(error.response.data.message)
    },
  },
}
</script>

<template>
  <div
    style="width: 620px"
  >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="body-1 font-weight-medium">
            Payment Method
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="ml-2 pt-0">
            <v-row class="pt-0">
              <v-col
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters.mpesa"
                  label="Mpesa"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters.creditCard"
                  label="Credit Card"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters.paypal"
                  label="Paypal"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters.cash"
                  label="Cash"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters.bankTransfer"
                  label="Bank Transfer"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters.other"
                  label="Other"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: {
        mpesa: false,
        creditCard: false,
        paypal: false,
        bankTransfer: false,
        cash: false,
        other: false,
      },
      filterLabel: 'all customers',
    }
  },
  mounted() {
    if (this.initialFilters) {
      this.filters = this.initialFilters
    }
    this.onCheckboxChange()
  },
  methods: {
    onCheckboxChange() {
      this.generateFilterLabel()
      const data = {
        filters: this.filters,
        label: this.filterLabel,
      }
      this.$emit('status-change', data)
    },
    generateFilterLabel() {
      const labels = []
      if (this.filters.mpesa) {
        labels.push('Mpesa')
      }
      if (this.filters.creditCard) {
        labels.push('Credit Card')
      }
      if (this.filters.paypal) {
        labels.push('paypal')
      }
      if (this.filters.bankTransfer) {
        labels.push('Bank Transfer')
      }
      if (this.filters.cash) {
        labels.push('Cash')
      }
      if (labels.length === 0) {
        this.filterLabel = 'all customers'

        return
      }
      this.filterLabel = labels[0]
      if (labels.length === 2) {
        this.filterLabel = `${this.filterLabel} and ${labels[1]}`
      }
      if (labels.length === 3) {
        this.filterLabel = `${this.filterLabel}, ${labels[1]} and ${labels[2]}`
      }
    },
  },
}
</script>

<template>
  <div
    style="width: 620px"
  >
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel
        v-for="(group, groupName) in visibleFilterGroups"
        :key="groupName"
        class="mb-0 mt-0"
      >
        <v-expansion-panel-header>
          <p class="body-1 font-weight-medium">
            {{ group.label }}
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="ml-2 pt-0">
            <v-row class="pt-0">
              <v-col
                v-for="item in group.items"
                :key="item.label"
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="filters[item.model]"
                  :label="item.label"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
    showConfigurationDetails: {
      type: Boolean,
      default: false,
    },
    showServiceStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: [0],
      filters: {},
      filterGroups: {
        serviceStatus: {
          label: 'Service Status',
          items: [
            { model: 'internetAccess', label: 'Internet Access' },
            { model: 'noInternetAccess', label: 'No Internet Access' },
            { model: 'debt', label: 'Debt' },
            { model: 'noDebt', label: 'No Debt' },
            { model: 'credits', label: 'Credits' },
            { model: 'activePlan', label: 'Active Plan' },
            { model: 'expiredPlan', label: 'Expired Plan' },
            { model: 'noInternetPlan', label: 'No Internet Plan' },
            { model: 'exemptedFromDisconnection', label: 'Exempted From Disconnection' },
            { model: 'dormant', label: 'Dormant Account' },
            { model: 'noPhoneNumber', label: 'No Phone Number' },
          ],
        },
        configurationDetails: {
          label: 'Internet Connection Type',
          items: [
            { model: 'staticIp', label: 'Static IP' },
            { model: 'pppoe', label: 'PPPoE' },
            { model: 'dynamicIp', label: 'Dynamic IP' },
            { model: 'hotspot', label: 'Hotspot' },
          ],
        },
      },
      filterLabel: 'all customers',
    }
  },
  computed: {
    visibleFilterGroups() {
      const groups = { ...this.filterGroups }

      if (!this.showServiceStatus) {
        delete groups.serviceStatus
      }

      if (!this.showConfigurationDetails) {
        delete groups.configurationDetails
      }

      return groups
    },
  },
  created() {
    this.initializeFilters()
  },
  mounted() {
    this.onCheckboxChange()
  },
  methods: {
    initializeFilters() {
      const allFilters = {}
      Object.values(this.filterGroups).forEach(group => {
        group.items.forEach(item => {
          allFilters[item.model] = this.initialFilters[item.model] || false
        })
      })
      this.filters = allFilters
    },
    onCheckboxChange() {
      this.generateFilterLabel()
      this.$emit('status-change', {
        filters: this.filters,
        label: this.filterLabel,
      })
      console.log(JSON.stringify(this.filters))
    },
    generateFilterLabel() {
      const filterLabels = this.generateLabelsFromFilters()
      this.filterLabel = filterLabels.join(', ').replace(/, ([^,]*)$/, ' and $1') || 'all customers'
    },
    generateLabelsFromFilters() {
      return Object.entries(this.filters).reduce((acc, [key, value]) => {
        if (value) {
          const filterGroupEntry = this.findFilterGroupEntry(key)
          if (filterGroupEntry) acc.push(filterGroupEntry.label)
        }

        return acc
      }, [])
    },
    findFilterGroupEntry(filterModel) {
      const entries = Object.entries(this.filterGroups)
      for (let i = 0; i < entries.length; i++) {
        const [, group] = entries[i]
        const entry = group.items.find(item => item.model === filterModel)
        if (entry) {
          return entry
        }
      }

      return null
    },
  },
}
</script>
